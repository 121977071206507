import { useEffect, useRef } from "react"
import type { ComponentType } from "react"

export function TrackIframeClick(
    Component: ComponentType<any>
): ComponentType<any> {
    return (props) => {
        const containerRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            // When the component mounts, disable pointer events on the iframe so the container gets the click.
            if (containerRef.current) {
                const iframe = containerRef.current.querySelector("iframe")
                if (iframe) {
                    iframe.style.pointerEvents = "none"
                }
            }
        }, [])

        const handleClick = (event: React.MouseEvent) => {
            if (window.umami) {
                window.umami.track("Click video")
            }
            // After tracking, re-enable pointer events on the iframe so the user can interact with it.
            if (containerRef.current) {
                const iframe = containerRef.current.querySelector("iframe")
                if (iframe) {
                    iframe.style.pointerEvents = "auto"
                }
            }
            if (props.onClick) {
                props.onClick(event)
            }
        }

        return (
            <div ref={containerRef} onClick={handleClick}>
                <Component {...props} />
            </div>
        )
    }
}
